.payment-table table {
	@apply w-full h-full border-collapse rounded-t-10 border-hidden;
	box-shadow: 0 0 0 1px #e8e8e8;
}

.payment-table td {
	@apply font-medium border border-solid border-gray-10 h-7 p-0;
}

.payment-table thead > tr > th:first-child {
	@apply rounded-tl-10;
}

.payment-table table th {
	@apply bg-gray-1;
}

.payment-table thead > tr > th:last-child {
	@apply rounded-tr-10;
}

.payment-table thead > tr > th {
	@apply font-medium text-base text-gray-80 bg-gray-1 border border-solid border-gray-10 h-7 md:px-4 2xl:px-6 py-3;
}

.payment-table tbody > tr:hover {
	box-shadow: 0 -1px 0 1px #a2a2a2, 0 0 0 1px #a2a2a2;
}

.payment-table-row-selected {
	@apply bg-gray-10-opacity-50;
}

.payment-table .rc-table-content {
	@apply h-full;
}

.payment-table .rc-table-container {
	@apply h-full;
}

.payment-table-row-overdue {
	@apply bg-lavender-pinkapprox-60;
}

.payment-table-row-pending {
	@apply bg-seashell-peach;
}

.table-payment table {
	@apply w-full;
	box-shadow: none;
}

.table-payment table > tr {
	@apply h-14;
}

.table-payment table > tr:first-child > td {
	@apply bg-gray-1;
}

.table-payment table > tr:first-child > td:not(:last-child) {
	@apply w-36;
}
