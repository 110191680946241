.search-group {
	position: relative;
	height: 48px;
}
.search-input {
	@apply block py-2 px-3 border-[1px] border-gray-22 rounded-lg placeholder:text-gray-22 outline-none flex-1;
}

.search-button {
	@apply bg-primary-violet text-white rounded-lg py-1.25 px-3 justify-center;
	position: absolute;
	right: 12px;
	z-index: 2;
	top: 8px;
	height: fit-content;
}
.border-top {
	@apply border-t-gray-30;
	border-top-style: solid;
	border-top-width: 1px;
}
