.search-location--input {
	line-height: 22px;
	padding-left: 62px;
	@apply w-full outline-none border border-gray-10 transition hover:border-gray-hover-1 focus:border-cornflower-blue py-4.5 rounded-10 text-gray-hover-2 placeholder:text-gray-hover-1;
}

.search-location--select-area {
	top: 70px;
	@apply flex-col absolute w-full shadow-drop-shadow-expanded border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white-100 z-50;
}

.search-location--select-list {
	@apply flex-col w-full p-3 shadow-drop-shadow-expanded overflow-y-scroll;
}
