.border-left {
	@apply border-gray-22 border-l;
	border-left-style: solid;
}
.border-left-error {
	@apply border-red border-l;
	border-left-style: solid;
}

.border-card-product {
	@apply border border-gray-10 rounded-10;
	border-style: solid;
}
