.truck-card-dot {
	@apply relative;
}

.truck-card-dot:not(:last-child):after {
	@apply absolute -top-[3px] -right-[12px] text-gray-40;
	content: ' \2219 ';
}

.truck-card-code-dot {
	@apply relative;
}

.truck-card-code-dot:not(:last-child):after {
	@apply absolute -top-[1px] -right-[12px] text-gray-80;
	content: ' \2219 ';
}
