.product-table table {
	@apply w-full h-full border-collapse rounded-10 border-hidden;
	box-shadow: 0 0 0 1px #e8e8e8;
}

.product-table td {
	@apply font-medium border border-solid border-gray-10 h-7 p-3;
}

.product-table thead > tr > th:first-child {
	@apply rounded-tl-10;
}

.product-table thead > tr > th:last-child {
	@apply rounded-tr-10;
}

.product-table thead > tr > th {
	@apply font-medium text-base text-gray-80 bg-gray-1 border border-solid border-gray-10 h-7 p-3;
}

.product-table tbody > tr:last-child > td:first-child {
	@apply rounded-bl-10;
}

.product-table tbody > tr:last-child > td:last-child {
	@apply rounded-br-10;
}

.product-table tbody > tr:hover {
	box-shadow: 0 0 0 1px #a2a2a2;
}

.product-table .rc-table-content {
	@apply h-full;
}

.product-table .rc-table-container {
	@apply h-full;
}

.product-table .rc-table-row:hover {
	cursor: pointer;
}

.product-table-row-pending {
	@apply bg-seashell-peach;
}
