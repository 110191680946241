.side-bar-shadow {
	box-shadow: 10px 30px 40px -40px rgb(0 0 0 / 50%);
}

.side-bar-border {
	@apply border-gray-10 rounded-10;
	border-style: solid;
	border-width: 1px;
}

.border-bottom {
	@apply border-b border-b-gray-10 last-of-type:border-b-0;
	border-bottom-style: solid;
}

.border-top {
	@apply border-t border-t-gray-10 first:border-0;
	border-top-style: solid;
}
