.producer-card-border-left {
	border: 1px solid #e8e8e8;
}

.producer-card-border-left:before {
	height: calc(100% + 2px);
	border-bottom-left-radius: 10px;
	border-top-left-radius: 10px;
	content: '';
	position: absolute;
	top: -1px;
	left: -1px;
	border-left: 16px solid #20ac93;
}

.border-bottom-solid {
	border-top: 1px solid #f3f5f8;
	border-bottom: 1px solid #e8e8e8;
}
