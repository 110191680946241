.side-bar {
	@apply w-[calc(100vw-26px)] sm:w-fit fixed sm:relative flex sm:flex-col items-center bg-white sm:bg-gray-1 border border-solid border-gray-10 sm:rounded-[10px] p-3 sm:p-0 xl-2xl:p-2 z-50;
}

.side-bar-group {
	@apply w-full flex sm:flex-col h-full justify-between items-center;
}

.side-bar-items {
	@apply hidden sm:flex sm:flex-col gap-5;
}

.avatar {
	@apply bg-gray-40 rounded-full w-[45px] h-[45px];
}
