.menus {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.menu-items {
	position: relative;
	font-size: 14px;
}

.menu-items a {
	display: block;
	font-size: inherit;
	color: inherit;
	text-decoration: none;
}

.menu-items button {
	display: flex;
	align-items: center;
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
}

button span {
	margin-left: 3px;
}

.menu-items > a,
.menu-items button {
	text-align: left;
	padding: 16px 18px;
	border-radius: 5px;
}

/* .menu-items a:hover,
  .menus .menu-items button:hover {
    background-color: #f2f2f2;
  } */
/* 
.arrow-local::after {
	content: '';
	display: inline-block;
	margin-left: 0.28em;
	vertical-align: 0.09em;
	border-top: 0.42em solid;
	border-right: 0.32em solid transparent;
	border-left: 0.32em solid transparent;
} */

.dropdown {
	min-width: 256px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	font-size: 0.875rem;
	z-index: 9999;
	min-width: 10rem;
	padding: 12px 14px;
	list-style: none;
	background-color: #fff;
	border-radius: 0.5rem;
	display: none;
	flex-direction: column;
}

.dropdown.show {
	display: flex;
}

.dropdown .dropdown-submenu {
	position: absolute;
	right: 100%;
	top: -7px;
}

/* content */

.content {
	max-width: 1200px;
	margin: 0 auto;
	padding: 3rem 20px;
}

.content h2 {
	margin-bottom: 1rem;
}

.content a {
	color: #cc3852;
	margin-right: 10px;
}
