.location--input {
	line-height: 20px;
	@apply w-full outline-none border-[1px] border-gray-10 transition-colors hover:border-gray-20 py-2.5 px-3 rounded-md text-black placeholder:text-black;
}

.location--select-area {
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	top: 67px;
	@apply flex-col z-10 absolute w-full  border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white-100;
}

.location--select-list {
	@apply flex-col w-fit p-3 shadow-drop-shadow-expanded overflow-scroll;
}

.location--input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	@apply text-gray-22 opacity-100;
}

.location--input-error {
	@apply border-red;
}
