@import '../node_modules/@sypac/component-library-react/dist/components/stencil-generated/styles/core.tokens.css';
@import '../node_modules/@sypac/component-library-react/dist/components/stencil-generated/styles/sypac.tokens.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.Toastify__toast-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	background-color: #f44a77 !important;
}

.notification .Toastify__toast-icon,
.notification_custom .Toastify__toast-icon {
	display: none;
}
.notification .Toastify__progress-bar,
.notification_custom .Toastify__progress-bar {
	background-color: transparent;
}

.notification,
.notification_custom {
	@apply shadow-notify border border-solid border-gray-10 bg-alabaster rounded-xl p-1;
}

.notification .Toastify__close-button svg,
.notification_custom .Toastify__close-button svg {
	fill: #454545;
}

.notification {
	@apply -right-10;
}

.notification_custom {
	@apply right-40;
	margin-bottom: 0 !important;
}

.dashed-box {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D3D4D5FF' strokeWidth='1' stroke-dasharray='6%2c 6' stroke-dashoffset='75' strokeLinecap='square'/%3e%3c/svg%3e");
	border-radius: 12px;
}
input[type='radio'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	width: 20px;
	height: 20px;
	padding: 7px;
	background-clip: content-box;
	border: 1px solid #e7e6e7;
	background-color: #e7e6e7;
	border-radius: 6px;
}

input[type='radio']:checked {
	border: 1px solid #22c55e;
	background-color: #22c55e;
}
.react-tel-input {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif !important;
}
.react-tel-input .flag-dropdown {
	border: 0 !important;
	border-radius: 6px 0 0 6px !important;
	border-right: 1px !important;
	border-style: solid !important;
	border-color: rgba(172, 176, 181, 1) !important;
}
.react-tel-input .form-control {
	border: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	height: 42px !important;
}

.react-tel-input .selected-flag {
	padding: 0 !important;
	border-radius: 6px 0 0 6px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 6px !important;
}
.react-tel-input input {
	width: 100% !important;
}
.react-tel-input .selected-flag .flag {
	display: initial !important;
	overflow: initial !important;
	border-radius: initial !important;
	margin-top: -7px !important;
}
.react-tel-input .country-list .flag {
	overflow: initial !important;
	border-radius: initial !important;
}
.react-tel-input .country-list .country {
	padding: 10px !important;
}
.react-tel-input .country-list {
	border-radius: 6px !important;
}
.react-pdf__Page__annotations {
	display: none;
}

.disabled-drop-down .choices__inner {
	background-color: #e8e8e8 !important;
	border-color: #e8e8e8 !important;
	color: #a2a2a2 !important;
}

.border_required .choices__inner {
	border-color: rgb(247, 72, 39) !important;
}
.input-icons .input {
	height: 42px !important;
}
