.side-bar-item {
	@apply relative flex items-center justify-center rounded-11 w-[32px] h-[32px] text-gray-40 cursor-pointer hover:text-black hover:bg-gray-10;
}

@screen xl-2xl {
	.side-bar-item {
		@apply w-[42px] h-[42px];
	}
}

.side-bar-alt {
	@apply absolute text-black left-[55px] px-[10px] py-[5px] h-[20px] rounded top-[6px] bottom-[14px] bg-gray-10 z-30;
}

.side-bar-alt-txt {
	@apply before:content-[''] before:block before:w-[0px] before:absolute before:border-solid before:border-y-[6px] before:border-r-[6px] before:border-y-transparent before:border-l-transparent before:border-gray-10 before:left-[-16px] before:top-[4px];
}

.active {
	@apply bg-gradient-primary text-white cursor-auto hover:text-white;
}
