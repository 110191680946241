.product-group-bg-image {
	width: 150px;
	height: 150px;
	margin-bottom: 25px;
	background-position: center center;
	@apply relative rounded-10 bg-cover bg-center;
}

.product-group-bg-image:hover {
	background: #00000033;
	background-blend-mode: multiply;
	@apply bg-cover bg-center;
}

.border-card {
	@apply border-[1px] border-gray-10 mt-7.5 p-15 rounded-10 flex items-center gap-[59px] flex-wrap justify-center;
	border-style: solid;
}
